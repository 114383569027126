import axios from "axios";
import { useEffect, useState } from "react";

import Logo from "../assets/Home/esirsLogo.png";
import Alert from "../components/Alert";
import styles from "./style.module.css";

const TCCVerification = () => {
	const [TCCNumber, setTCCNumber] = useState("");
	const [searching, setSearching] = useState(false);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			5000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	const search = async (e) => {
		e.preventDefault();
		setSearching(true);

		try {
			const res = await axios
				.get(`/getwebcerts/${TCCNumber}`, {
					headers: {
						secureddata: "VGhpc2lzTm9ybGljcw=="
					}
				})
				.then((res) => res.data);

			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res?.msg
			});
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setSearching(false);
		}
	};

	return (
		<main className={styles.TCC__verification_page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<img src={Logo} alt="Enugu state logo" />
			<h1>Enugu State TCC Verification</h1>
			<form className={styles.form} onSubmit={search}>
				<input
					value={TCCNumber}
					onChange={(e) => setTCCNumber(e.target.value)}
					placeholder="Enter Certificate Number"
					autoFocus
				/>
				<button className="primary__btn" type="submit">
					{searching ? "Searching..." : "Search"}
				</button>
			</form>
		</main>
	);
};

export default TCCVerification;
