import React, { createRef, lazy, Suspense, useEffect, useState } from "react";
import {
	Redirect,
	Route,
	Switch,
	useLocation,
	useRouteMatch
} from "react-router-dom";

// Icons
import CGTblackIcon from "../assets/CGTblackIcon.svg";
import AnalyticsIcon from "../assets/Header/analytics.svg";
import AnnualReturnsIcon from "../assets/Header/annualReturns.svg";
import ANSSIDIcon from "../assets/Header/anssid.svg";
import BillsIcon from "../assets/Header/bills.svg";
import ChangePasswordIcon from "../assets/Header/changePassword.svg";
import CouponIcon from "../assets/Header/coupon.svg";
import DashboardIcon from "../assets/Header/dashboard.svg";
import EmailPhoneChangeIcon from "../assets/Header/emailPhoneChange.svg";
import MDAIcon from "../assets/Header/mdas.svg";
import ReceiptIcon from "../assets/Header/receipts.svg";
import SettingsIcon from "../assets/Header/settings.svg";
import TotalCollectionsIcon from "../assets/Header/totalCollections.svg";
import UserManagementIcon from "../assets/Header/userManagement.svg";
import NewsReelIcon from "../assets/NewsReel/BlackPaper.svg";
import WHTblackLogo from "../assets/WHTblackLogo.svg";
import ErrorBoundary from "../components/ErrorBoundary";
import Header from "../components/header";
import Sidenav from "../components/sidenav";
import UserSidenav from "../components/sidenav/user";
import Spinner from "../components/spinner";
import styles from "./style.module.css";

const Home = lazy(() => import("./Home"));
const SpecialDashboard = lazy(() => import("./SpecialDashboard"));
const Summary = lazy(() => import("./summary"));
const coy_ANSSID = process.env.REACT_APP_ANSSID;

let userInfo;
try {
	userInfo = JSON.parse(localStorage.getItem("user_info"));
} catch (e) {
	userInfo = {};
}

const Dashboard = () => {
	const [sidebarOpen, toggleSidebar] = useState(window.innerWidth > 768);
	const { pathname } = useLocation();
	const { path } = useRouteMatch();

	const navRef = createRef();
	const menuRef = createRef();

	const isUser = /^(individual)|(corporate)$/i.test(userInfo?.account_type);
	const isCorporate = /^corporate$/i.test(userInfo?.account_type);

	const isGovernor = userInfo?.roles?.[0]?.Claims?.find((cl) =>
		/governor/i.test(cl.Name)
	);

	const allowedRoutes = (userInfo?.roles?.[0]?.Claims || []).reduce(
		(acc, cl) => {
			acc[cl.Route] = true;
			return acc;
		},
		{}
	);

	const hasDashboard = Boolean(
		Object.keys(allowedRoutes).find((key) => key === "/")
	);

	const routes = {
		[`${path}`]: {
			title: isUser ? "Home" : "Dashboard"
		},
		[`${path}details`]: {
			title: "Details",
			component: isCorporate
				? lazy(() => import("./CompanyDetails"))
				: lazy(() => import("./UserDetails"))
		},
		[`${path}password`]: {
			title: "Change Password",
			component: lazy(() => import("./ChangePassword"))
		},
		[`${path}support`]: {
			title: "Contact Support",
			component: lazy(() => import("./Support"))
		},
		[`${path}PAYE-records`]: {
			title: "My Paye Record",
			component: lazy(
				() => import("./PAYE/Paye-Record/corporatePayeRecord")
			)
		},
		[`${path}hni/tax`]: {
			title: `HNI Tax`,
			component: lazy(() => import("./HNI/home"))
		},
		[`${path}hni/prepare-tax`]: {
			title: `Create HNI Tax`,
			component: lazy(() => import("./HNI/hniTax"))
		},
		[`${path}hni/edit-setup`]: {
			title: `Edit Setup`,
			component: lazy(() => import("./HNI/editSetup"))
		},
		[`${path}PAYE-filing-corporate`]: {
			title: "PAYE Filing",
			component: lazy(
				() => import("./AnnualReturns/annualReturnsCorporate")
			)
		},
		[`${path}paye-filing/corporate-view`]: {
			title: "PAYE Filing View",
			icon: AnnualReturnsIcon,
			component: lazy(() => import("./AnnualReturns/corporateViewFiling"))
		},
		[`${path}paye-file-returns`]: {
			title: "E-Filing",
			component: lazy(() => import("./AnnualReturns/fileAnnualReturns"))
		},
		[`${path}file-annual-returns-period`]: {
			title: "PAYE Filing",
			component: lazy(
				() => import("./AnnualReturns/coporateFilingPeriod")
			)
		},
		//
		[`${path}WHT-filing-corporate`]: {
			title: "WHT Filing",
			component: lazy(
				() => import("./AnnualReturns/annualReturnsCorporate")
			)
		},
		[`${path}wht-filing/corporate-view`]: {
			title: "WHT Filing View",
			icon: AnnualReturnsIcon,
			component: lazy(() => import("./AnnualReturns/corporateViewFiling"))
		},
		[`${path}wht-file-returns`]: {
			title: "WHT Filing",
			component: lazy(() => import("./AnnualReturns/fileAnnualReturns"))
		},
		[`${path}file-wht-annual-returns-period`]: {
			title: "WHT Filing",
			component: lazy(
				() => import("./AnnualReturns/coporateFilingPeriod")
			)
		},
		//
		[`${path}PAYE/PayeSchedule`]: {
			title: "PAYE Schedule",
			component: lazy(() => import("./PAYE/Create-PAYE-assessment"))
		},
		[`${path}Paye-Review`]: {
			title: "Paye Details",
			component: lazy(() => import("./PAYE/Paye-Record/recordsView"))
		},
		[`${path}PAYE/PAYE-Assessment`]: {
			title: "PAYE-Assessment",
			component: lazy(
				() => import("./PAYE/PAYE-Assessment/corporateIndex")
			)
		},
		[`${path}Assessment`]: {
			title: "PAYE-Assessment Continuation",
			component: lazy(() => import("./PAYE/PAYE-Assessment/assessment"))
		},
		[`${path}Paye/Review`]: {
			title: "Paye Details",
			component: lazy(() => import("./PAYE/Paye-Record/corporateView"))
		},
		[`${path}withholding/records/view`]: {
			title: "Withholding Records View",
			component: lazy(() => import("./WHT/Self-Service/view"))
		},
		[`${path}withholding/edit`]: {
			title: "Withholding Records Edit",
			component: lazy(
				() =>
					import(
						"./WHT/Self-Service/corporateEditEmployerInformation"
					)
			)
		},
		[`${path}withholding/records`]: {
			title: "Withholding Records",
			component: lazy(() => import("./WHT/Self-Service"))
		},
		[`${path}withholding/assessment`]: {
			title: "Withholding Assessment",
			component: lazy(() => import("./WHT/Self-Service/generate"))
		},
		[`${path}capital-gain-tax/records/view`]: {
			title: "Capital Gain Records View",
			component: lazy(() => import("./CGT/Self-Service/view"))
		},
		[`${path}capital-gain-tax/edit`]: {
			title: "Capital Gain Records Edit",
			component: lazy(() => import("./CGT/Generation/edit"))
		},
		[`${path}capital-gain-tax/self-service/records`]: {
			title: "Capital Gain Tax Records",
			component: lazy(() => import("./CGT/Self-Service/index"))
		},
		[`${path}capital-gain-tax/assessment`]: {
			title: "Capital Gain Tax assessment",
			component: lazy(() => import("./CGT/Generation/generate"))
		},
		[`${path}pt/pt-search`]: {
			title: "PT Search",
			component: lazy(() => import("./PT/self-service/pt-selfRecord"))
		},
		[`${path}pt/pt-records/view`]: {
			title: "PT View",
			component: lazy(
				() => import("./PT/self-service/pt-selfrecord-view")
			)
		},
		[`${path}pt/pt-assessment`]: {
			title: "PT Assessment",
			component: lazy(
				() => import("../Dashboard/PT/self-service/create-records")
			)
		},
		[`${path}pt/pt-records/pt-edit`]: {
			title: "PT Edit",
			component: lazy(
				() => import("./PT/self-service/pt-selfrecords-edit")
			)
		},
		[`${path}pt/pt-records/pt-category`]: {
			title: "PT Category",
			component: lazy(
				() => import("./PT/self-service/pt-self-editcategory")
			)
		}
	};

	const adminRoutes = {
		[`${path}`]: {
			title: "Dashboard",
			icon: DashboardIcon
		},
		[`${path}total-collections`]: {
			title: "Total Collections",
			component: lazy(() => import("./Total-Collections")),
			icon: TotalCollectionsIcon
		},
		[`${path}bills/cleared-bills`]: {
			title: "Cleared Bills",
			icon: BillsIcon,
			component: lazy(() => import("./Bills/cleared"))
		},
		[`${path}bills/uncleared-bills`]: {
			title: "Uncleared Bills",
			icon: BillsIcon,
			component: lazy(() => import("./Bills/uncleared"))
		},
		[`${path}mdas`]: {
			title: "MDA",
			icon: MDAIcon,
			component: lazy(() => import("./MDAs"))
		},

		// PAYE Filing Admin navs
		[`${path}paye-filing/file-returns`]: {
			title: "PAYE Filing",
			icon: AnnualReturnsIcon,
			component: lazy(() => import("./AnnualReturns/fileAnnualReturns"))
		},
		[`${path}paye-filing/admin-filing`]: {
			title: "PAYE Filing",
			icon: AnnualReturnsIcon,
			component: lazy(
				() => import("./AnnualReturns/adminFileAnnualReturn")
			)
		},
		[`${path}paye-filing/admin-filing-table`]: {
			title: "PAYE Filing Process",
			icon: AnnualReturnsIcon,
			component: lazy(
				() => import("./AnnualReturns/annualFilingCorporateTable")
			)
		},
		[`${path}paye-filing/review`]: {
			title: "Review PAYE Filing",
			icon: AnnualReturnsIcon,
			component: lazy(() => import("./AnnualReturns/paye/reviewer"))
		},
		[`${path}paye-filing/search`]: {
			title: "Authorise PAYE Filing",
			icon: AnnualReturnsIcon,
			component: lazy(() => import("./AnnualReturns"))
		},
		[`${path}paye-filing/view`]: {
			title: "E-Filing View",
			icon: AnnualReturnsIcon,
			component: lazy(() => import("./AnnualReturns/corporateViewFiling"))
		},
		[`${path}paye-filing/corporate-view`]: {
			title: "PAYE Filing View",
			icon: AnnualReturnsIcon,
			component: lazy(() => import("./AnnualReturns/corporateViewFiling"))
		},
		// PAYE Filing Admin navs

		// WHT Filing Admin navs
		[`${path}wht-filing/file-returns`]: {
			title: "WHT Filing",
			icon: AnnualReturnsIcon,
			component: lazy(
				() => import("./AnnualReturns/wht/fileWHTAnnualReturns")
			)
		},
		[`${path}wht-filing/admin-filing`]: {
			title: "WHT Filing",
			icon: AnnualReturnsIcon,
			component: lazy(
				() => import("./AnnualReturns/wht/whtAdminFileAnnualReturn")
			)
		},
		[`${path}wht-filing/admin-filing-table`]: {
			title: "WHT Filing Process",
			icon: AnnualReturnsIcon,
			component: lazy(
				() => import("./AnnualReturns/wht/whtAdminFileAnnualReturn")
			)
		},
		[`${path}wht-filing/review`]: {
			title: "Review WHT Filing",
			icon: AnnualReturnsIcon,
			component: lazy(() => import("./AnnualReturns/wht/WHTReviewer"))
		},
		[`${path}wht-filing/search`]: {
			title: "Authorise WHT Filing",
			icon: AnnualReturnsIcon,
			component: lazy(() => import("./AnnualReturns/wht/WHTAuthoriser"))
		},
		[`${path}wht-filing/view`]: {
			title: "WHT Filing View",
			icon: AnnualReturnsIcon,
			component: lazy(() => import("./AnnualReturns/wht/WHTViewFiling"))
		},
		[`${path}wht-filing/corporate-view`]: {
			title: "WHT Filing View",
			icon: AnnualReturnsIcon,
			component: lazy(() => import("./AnnualReturns/wht/WHTViewFiling"))
		},
		// WHT Filing Admin navs

		[`${path}paye-filing/employee`]: {
			title: "PAYE Filing Employee",
			icon: AnnualReturnsIcon,
			component: lazy(() => import("./AnnualReturns/employee"))
		},
		[`${path}paye-filing/view-employees`]: {
			title: "PAYE Filing Employee",
			icon: AnnualReturnsIcon,
			component: lazy(() => import("./AnnualReturns/viewEmployees"))
		},
		[`${path}paye-filing/employee-details`]: {
			title: "PAYE Filing Employee Details",
			icon: AnnualReturnsIcon,
			component: lazy(() => import("./AnnualReturns/editEmployeeDetails"))
		},
		[`${path}PAYE/create-PAYE-assessment`]: {
			title: "Create PAYE Assessment",
			icon: MDAIcon,
			component: lazy(() => import("./PAYE/PAYE-Assessment"))
		},
		[`${path}create-mda`]: {
			title: "Create MDA",
			icon: MDAIcon,
			component: lazy(() => import("./MDAs/create"))
		},
		[`${path}statetin/individual`]: {
			title: `${coy_ANSSID} - Individual`,
			icon: ANSSIDIcon,
			component: lazy(() => import("./StateTIN/individual"))
		},
		[`${path}statetin/corporate`]: {
			title: `${coy_ANSSID} - Organization`,
			icon: ANSSIDIcon,
			component: lazy(() => import("./StateTIN/corporate"))
		},
		[`${path}statetin/audit`]: {
			title: `${coy_ANSSID} - Auditor`,
			icon: ANSSIDIcon,
			component: lazy(() => import("./StateTIN/audit"))
		},
		[`${path}statetin/authorize`]: {
			title: `${coy_ANSSID} - Authorize`,
			icon: ANSSIDIcon,
			component: lazy(() => import("./StateTIN/authorize"))
		},
		[`${path}audit-statetin/individual/`]: {
			title: `${coy_ANSSID} - Auditor`,
			icon: ANSSIDIcon,
			component: lazy(() => import("./StateTIN/individualAuditDetails"))
		},
		[`${path}audit-statetin/corporate/`]: {
			title: `${coy_ANSSID} - Auditor`,
			icon: ANSSIDIcon,
			component: lazy(() => import("./StateTIN/corporateAuditDetails"))
		},
		[`${path}certificate/authorise/da`]: {
			title: `Authorise Certificates`,
			icon: ReceiptIcon,
			component: lazy(() => import("./Certificate/authorise.da"))
		},
		[`${path}certificate/da/generate`]: {
			title: `Generate Automated DA Certificate`,
			icon: ReceiptIcon,
			component: lazy(() => import("./Certificate/generate.auto.da.cert"))
		},
		[`${path}certificate/da`]: {
			title: `Direct Assessment Automated Certificate`,
			icon: ReceiptIcon,
			component: lazy(() => import("./Certificate/auto.da.certificate"))
		},
		[`${path}certificate/da-manual-entry-list`]: {
			title: `Direct Assessment Manual Entry Certificate`,
			icon: ReceiptIcon,
			component: lazy(() => import("./Certificate/manual.da.certificate"))
		},
		[`${path}certificate/da-manual-entry`]: {
			title: `Direct Assessment Manual Entry Certificate`,
			icon: ReceiptIcon,
			component: lazy(() => import("./Certificate/da.manual.entry"))
		},
		[`${path}certificate/generate-da-manual-entry-certificate`]: {
			title: `Generate Direct Assessment Manual Entry Certificate`,
			icon: ReceiptIcon,
			component: lazy(
				() => import("./Certificate/generate.manual.da.cert")
			)
		},
		[`${path}coupon/generation/view`]: {
			title: `View Generated Coupon`,
			icon: CouponIcon,
			component: lazy(() => import("./Coupon/Generation/view"))
		},
		[`${path}coupon/generation`]: {
			title: `Coupon Generation Table`,
			icon: CouponIcon,
			component: lazy(() => import("./Coupon/Generation"))
		},
		[`${path}coupon/generate`]: {
			title: `Generate Coupon`,
			icon: CouponIcon,
			component: lazy(() => import("./Coupon/Generation/generate"))
		},
		[`${path}coupon/authorisation/view`]: {
			title: `Coupon Details`,
			icon: CouponIcon,
			component: lazy(() => import("./Coupon/Authorisation/view"))
		},
		[`${path}coupon/authorisation`]: {
			title: `Coupon Authorisation`,
			icon: CouponIcon,
			component: lazy(() => import("./Coupon/Authorisation"))
		},
		[`${path}coupon/review/view`]: {
			title: `Coupon Details`,
			icon: CouponIcon,
			component: lazy(() => import("./Coupon/Review/view"))
		},
		[`${path}coupon/review`]: {
			title: `Coupon Review`,
			icon: CouponIcon,
			component: lazy(() => import("./Coupon/Review"))
		},
		[`${path}coupon/purchase/records`]: {
			title: `Coupon Purchased Records`,
			icon: CouponIcon,
			component: lazy(() => import("./Coupon/Purchase"))
		},
		[`${path}coupon/purchase`]: {
			title: `Coupon Purchase`,
			icon: CouponIcon,
			component: lazy(() => import("./Coupon/Purchase/purchase"))
		},
		[`${path}coupon/validation`]: {
			title: `Coupon Validation`,
			icon: CouponIcon,
			component: lazy(() => import("./Coupon/Validation"))
		},
		[`${path}coupon/downloadable-coupons`]: {
			title: `Downloadable Coupon Table`,
			icon: CouponIcon,
			component: lazy(() => import("./Coupon/Download"))
		},
		[`${path}coupon/download`]: {
			title: `Download Coupons`,
			icon: CouponIcon,
			component: lazy(() => import("./Coupon/Download/view"))
		},
		[`${path}rats`]: {
			title: "RAT",
			component: lazy(() => import("./RATs"))
		},
		[`${path}jtbs`]: {
			title: "JTB",
			component: lazy(() => import("./JBTs"))
		},
		[`${path}analytics`]: {
			title: "Analytics",
			icon: AnalyticsIcon,
			component: lazy(() => import("./analysis"))
		},
		[`${path}lgaanalytics`]: {
			title: "LGA Analytics",
			icon: AnalyticsIcon,
			component: lazy(() => import("./analysis/lga"))
		},
		[`${path}mdaanalytics`]: {
			title: "MDA Analytics",
			icon: AnalyticsIcon,
			component: lazy(() => import("./analysis/mda"))
		},
		[`${path}revenueheadanalytics`]: {
			title: "Revenue Head Analytics",
			icon: AnalyticsIcon,
			component: lazy(() => import("./analysis/revenuehead"))
		},
		[`${path}handlingsystemanalytics`]: {
			title: "Handling System Analytics",
			icon: AnalyticsIcon,
			component: lazy(() => import("./analysis/handlingsystem"))
		},
		[`${path}billgroupanalytics`]: {
			title: "Billing Group Analytics",
			icon: AnalyticsIcon,
			component: lazy(() => import("./analysis/billinggroup"))
		},
		[`${path}mdaoptionsanalytics`]: {
			title: "Top & Bottom MDAs",
			icon: AnalyticsIcon,
			component: lazy(() => import("./analysis/mda.options"))
		},
		[`${path}create-multicoy`]: {
			title: `Create MDA`,
			icon: MDAIcon,
			component: lazy(() => import("./Multi-company/create"))
		},
		[`${path}multi-company/prepare-bill`]: {
			title: `Prepare Bill`,
			icon: MDAIcon,
			component: lazy(() => import("./Multi-company/meteredBill"))
		},

		// Orba Market
		[`${path}multi-company/orba-market`]: {
			title: `Orba Market`,
			icon: MDAIcon,
			component: lazy(
				() => import("./Multi-company/prepareOrbaMarketbill")
			)
		},

		[`${path}multi-company/orba-market-bill`]: {
			title: `Orba Market`,
			icon: MDAIcon,
			component: lazy(
				() => import("./Multi-company/orbaMarket.meteredBill")
			)
		},

		[`${path}multi-company/orba-market-edit`]: {
			title: `Edit Orba Market Bill`,
			icon: MDAIcon,
			component: lazy(
				() => import("./Multi-company/billActions/orbaMarket.editBill")
			)
		},
		[`${path}multi-company/orba-market-authorise`]: {
			title: `Authorise Orba Market Bill`,
			icon: MDAIcon,
			component: lazy(
				() => import("./Multi-company/authoriseOrbaMarket.meteredBill")
			)
		},
		// Orba Market

		[`${path}multi-company/new-haven`]: {
			title: `New Haven Shops`,
			icon: MDAIcon,
			component: lazy(() => import("./Multi-company/newHavenCreate"))
		},
		[`${path}multi-company/new-haven-authorise`]: {
			title: `Authorise New Haven Shops`,
			icon: MDAIcon,
			component: lazy(() => import("./Multi-company/authorise.newHaven"))
		},
		[`${path}multi-company/create-new-haven`]: {
			title: `New Haven Shops`,
			icon: MDAIcon,
			component: lazy(() => import("./Multi-company/newHavenBillCreate"))
		},
		[`${path}multi-company/water`]: {
			title: `Water`,
			icon: MDAIcon,
			component: lazy(() => import("./Multi-company/water"))
		},
		[`${path}multi-company/mdaprofile`]: {
			title: `MDA Profile`,
			component: lazy(() => import("./Multi-company/profile"))
		},
		[`${path}multi-company/settings`]: {
			title: `Settings`,
			icon: UserManagementIcon,
			component: lazy(() => import("./Multi-company/settings"))
		},
		[`${path}multi-company/billing/view`]: {
			title: `View Bill`,
			icon: MDAIcon,
			component: lazy(
				() => import("./Multi-company/billActions/viewBill")
			)
		},
		[`${path}multi-company/billing/edit`]: {
			title: `Edit Bill`,
			icon: MDAIcon,
			component: lazy(
				() => import("./Multi-company/billActions/editBill")
			)
		},
		[`${path}multi-company/billing`]: {
			title: `Bills`,
			icon: MDAIcon,
			component: lazy(() => import("./Multi-company/preparebill"))
		},
		[`${path}multi-company/billing4NewHaven/view`]: {
			title: `View Bill`,
			icon: MDAIcon,
			component: lazy(
				() => import("./Multi-company/billActions4NewHaven/viewBill")
			)
		},
		[`${path}multi-company/authorise`]: {
			title: `Authorise Bill`,
			icon: MDAIcon,
			component: lazy(() => import("./Multi-company/authoriseBill"))
		},
		[`${path}multi-company/billing4NewHaven/edit`]: {
			title: `Edit Bill`,
			icon: MDAIcon,
			component: lazy(
				() => import("./Multi-company/billActions4NewHaven/editBill")
			)
		},
		[`${path}Multi-company/New-haven-shops`]: {
			title: `New Haven Shops`,
			icon: MDAIcon,
			component: lazy(() => import("./Multi-company/newhavenshops"))
		},
		[`${path}create-new-haven-shops`]: {
			title: `Add New Haven Shops`,
			icon: MDAIcon,
			component: lazy(() => import("./Multi-company/createnewhaven"))
		},
		//PT
		[`${path}pt/pt-assessment`]: {
			title: "PT Assessment",
			component: lazy(() => import("./PT/pt-assessment"))
		},
		[`${path}pt/pt-search/view`]: {
			title: "PT View",
			component: lazy(() => import("./PT/pt-view"))
		},
		[`${path}pt/pt-search/pt-edit`]: {
			title: "PT Edit",
			component: lazy(() => import("./PT/pt-edit"))
		},
		[`${path}pt/pt-search/pt-category`]: {
			title: "PT Category",
			component: lazy(() => import("./PT/edit-category"))
		},

		[`${path}pt/pt-search`]: {
			title: "PT Records",
			component: lazy(() => import("./PT/pt-search"))
		},

		[`${path}pt/pt-authoriser/view`]: {
			title: "PT View",
			component: lazy(() => import("./PT/authoriser-view"))
		},
		[`${path}pt/pt-authorizer`]: {
			title: "PT Authoriser",
			component: lazy(() => import("./PT/pt-authoriser"))
		},
		[`${path}pt/pt-reviewer/view`]: {
			title: "PT View",
			component: lazy(() => import("./PT/reviewer-view"))
		},

		[`${path}pt/pt-reviewer`]: {
			title: "PT Reviewer",
			component: lazy(() => import("./PT/pt-reviewer"))
		},
		[`${path}pt/pt-setup`]: {
			title: "PT setup",
			component: lazy(() => import("./PT/pt-setup"))
		},
		[`${path}pt/pt-add-setup`]: {
			title: "PT Add Setup",
			component: lazy(() => import("./PT/pt-add-setup"))
		},
		//reducing-balance
		[`${path}reducing-balance/reducing-balance-receipts`]: {
			title: `Reducing Balance Receipt`,
			component: lazy(() => import("../ReducingBalance/reducingBalanceR"))
		},
		[`${path}Maker-checker/authoriser`]: {
			title: `Authoriser`,
			component: lazy(() => import("../Maker-checker/authoriser"))
		},
		[`${path}Maker-checker/inputter`]: {
			title: `Inputter`,
			component: lazy(() => import("../Maker-checker/inputter"))
		},
		[`${path}Maker-checker/genreceipt`]: {
			title: `Generate Reciept`,
			component: lazy(() => import("../Maker-checker/genreceipt"))
		},
		[`${path}addnew`]: {
			title: `Add New`,
			icon: MDAIcon,
			component: lazy(() => import("../Maker-checker/addnew"))
		},
		[`${path}maker-checker/edit`]: {
			title: `Edit-Maker-checker`,
			component: lazy(() => import("../Maker-checker/edit"))
		},
		[`${path}maker-checker/view`]: {
			title: `View-Maker-checker`,
			component: lazy(() => import("../Maker-checker/view"))
		},
		[`${path}hni/tax`]: {
			title: `HNI / HNI Tax`,
			component: lazy(() => import("./HNI/home"))
		},
		[`${path}hni/setup`]: {
			title: `HNI / HNI Setup`,
			component: lazy(() => import("./HNI/hniSetupHome"))
		},
		[`${path}hni/prepare-tax`]: {
			title: `Create HNI Tax`,
			component: lazy(() => import("./HNI/hniTax"))
		},
		[`${path}hni/view`]: {
			title: `View HNI`,
			component: lazy(() => import("./HNI/viewHNI"))
		},
		[`${path}hni/edit`]: {
			title: `Edit HNI`,
			component: lazy(() => import("./HNI/editHNI"))
		},
		[`${path}hni/create-setup`]: {
			title: `Create Setup`,
			component: lazy(() => import("./HNI/hniSetup"))
		},
		[`${path}hni/edit-setup`]: {
			title: `Edit Setup`,
			component: lazy(() => import("./HNI/editSetup"))
		},
		[`${path}hni/authorise`]: {
			title: `Authorise`,
			component: lazy(() => import("./HNI/authorise"))
		},
		[`${path}create-jtb`]: {
			title: "Create JTB",
			component: lazy(() => import("./JBTs/create"))
		},
		[`${path}user-management/manage-users`]: {
			title: "Manage Users",
			icon: UserManagementIcon,
			component: lazy(() => import("./user"))
		},
		[`${path}user-management/manage-claims`]: {
			title: "Manage Claims",
			icon: UserManagementIcon,
			component: lazy(() => import("./Claim"))
		},
		[`${path}user-management/statetin-agent`]: {
			title: `${coy_ANSSID} Agent`,
			icon: UserManagementIcon,
			component: lazy(() => import("./ANSSIDAgent"))
		},
		[`${path}user-management/deactivate-agent`]: {
			title: "Deactivated Agent",
			icon: UserManagementIcon,
			component: lazy(() => import("./DeactivatedAgent"))
		},
		[`${path}user-management/manage-roles`]: {
			title: "Manage Roles",
			icon: UserManagementIcon,
			component: lazy(() => import("./roles"))
		},
		[`${path}direct-assessment/create-direct-assessment`]: {
			title: "Create Direct Assessment",
			icon: UserManagementIcon,
			component: lazy(
				() => import("../Direct-assessment/Create-direct-assessment")
			)
		},
		[`${path}direct-assessment/authorize-direct-assessment`]: {
			title: "Authorise Direct Assessment",
			icon: UserManagementIcon,
			component: lazy(() => import("../Direct-assessment/Authorize"))
		},
		[`${path}direct-assessment/settings`]: {
			title: "Direct Assessment Settings",
			icon: UserManagementIcon,
			component: lazy(() => import("../Direct-assessment/Settings"))
		},
		[`${path}direct-assessment/records`]: {
			title: "Direct Assessment Records",
			icon: UserManagementIcon,
			component: lazy(
				() => import("../Direct-assessment/Assessment-records")
			)
		},
		[`${path}direct-assessment/match`]: {
			title: "Match Payment Ref & Bill Number",
			icon: UserManagementIcon,
			component: lazy(() => import("../Direct-assessment/Match"))
		},
		[`${path}user-management/admin-change-password`]: {
			title: "Admin Change Password",
			icon: UserManagementIcon,
			component: lazy(() => import("./Admin/admin.change.password"))
		},
		[`${path}create-user`]: {
			title: "Create User",
			icon: UserManagementIcon,
			component: lazy(() => import("./user/create"))
		},
		[`${path}create-claim`]: {
			title: "Create Claim",
			component: lazy(() => import("./Claim/create"))
		},
		[`${path}create-role`]: {
			title: "Create Role",
			component: lazy(() => import("./roles/create"))
		},
		[`${path}corporate-profiling`]: {
			title: "Corporate Profiling",
			icon: MDAIcon,
			component: lazy(() => import("./StateTIN/corporate.profiling"))
		},
		[`${path}receipt`]: {
			title: "Receipt",
			icon: ReceiptIcon,
			component: lazy(() => import("./Receipt"))
		},
		[`${path}receiptprt/receiptref`]: {
			title: "Generate Receipt by Payment Reference",
			icon: ReceiptIcon,
			component: lazy(() => import("./ReceiptPymtRef/receiptref"))
		},

		//ENSSAA
		[`${path}ENNSAA/records`]: {
			title: "ENNSAA Records",
			component: lazy(() => import("./ENNSAA/ennsaarecords"))
		},
		[`${path}ENNSAA/preparebill`]: {
			title: "Prepare Bill",
			component: lazy(() => import("./ENNSAA/preparebill"))
		},
		[`${path}ENNSAA/authorizer`]: {
			title: "Authorise",
			component: lazy(() => import("./ENNSAA/ennsaaauthoriser"))
		},
		[`${path}ENNSAA/setup`]: {
			title: "Prepare Bill",
			component: lazy(() => import("./ENNSAA/ennsaasetup"))
		},
		[`${path}ensaa/records/view`]: {
			title: "ENNSAA Records View",
			component: lazy(() => import("./ENNSAA/ennsaarecordsview"))
		},
		[`${path}ensaa/records/edit`]: {
			title: "ENNSAA Records Edit",
			component: lazy(() => import("./ENNSAA/ensaarecordsedit"))
		},
		[`${path}ensaa/authoriser/view`]: {
			title: "Authoriser view",
			component: lazy(() => import("./ENNSAA/ennsaaauthoriseview"))
		},
		[`${path}ensaa/edit/category`]: {
			title: "Edit Category",
			component: lazy(() => import("./ENNSAA/editcategories"))
		},

		/*		[`${path}receipt/receiptours`]: {
			title: "Generate Receipt by Norlics Receipt Number",
			icon: ReceiptIcon,
			component: ReceiptPymtOurs
		},
		*/
		[`${path}receiptprt/receiptswitch`]: {
			title: "Generate Receipt by Provider Receipt Number",
			icon: ReceiptIcon,
			component: lazy(() => import("./ReceiptPymtRef/receiptswitch"))
		},
		[`${path}receiptprt/receiptinv`]: {
			title: "Generate Receipt by Vertical's Invoice Number",
			icon: ReceiptIcon,
			component: lazy(() => import("./ReceiptPymtRef/receiptinv"))
		},
		[`${path}receiptprt/mda.receipt`]: {
			title: "Generate Mda receipt",
			icon: ReceiptIcon,
			component: lazy(() => import("./ReceiptPymtRef/mda.receipt"))
		},
		[`${path}receiptprt/receipts`]: {
			title: "Generate Receipt",
			icon: ReceiptIcon,
			component: lazy(
				() => import("./ReceiptPymtRef/single.input.receipt")
			)
		},
		[`${path}receiptprt/payment-history`]: {
			title: "Bill Details & Payment History",
			icon: ReceiptIcon,
			component: lazy(() => import("./Receipt/payment.history"))
		},
		[`${path}settings/id-details`]: {
			title: "ID Details",
			icon: SettingsIcon,
			component: lazy(() => import("./Settings/id.details"))
		},
		[`${path}settings/statetin-setup`]: {
			title: `${coy_ANSSID} Setup`,
			icon: SettingsIcon,
			component: lazy(() => import("./Settings/stateTIN.setup"))
		},
		[`${path}settings/monthly-salary-range`]: {
			title: "Monthly Salary Range",
			icon: SettingsIcon,
			component: lazy(() => import("./Settings/monthly.salary.range"))
		},
		[`${path}settings/revenue-details`]: {
			title: "Revenue Details",
			icon: SettingsIcon,
			component: lazy(() => import("./Settings/revenue.details"))
		},
		[`${path}email-phone-change/record`]: {
			title: "Email/Phone Number Change Record",
			icon: EmailPhoneChangeIcon,
			component: lazy(() => import("./EmailPhoneChange/Records"))
		},
		[`${path}email-phone-change/change`]: {
			title: "Change Email And Phone Number",
			icon: EmailPhoneChangeIcon,
			component: lazy(() => import("./EmailPhoneChange/Change"))
		},
		[`${path}email-phone-change/auth-requests/view`]: {
			title: "Authorise Email/Phone Change",
			icon: EmailPhoneChangeIcon,
			component: lazy(
				() => import("./EmailPhoneChange/Authorisation/view")
			)
		},
		[`${path}email-phone-change/auth-requests`]: {
			title: "Email/Phone Number Change Auth Requests",
			icon: EmailPhoneChangeIcon,
			component: lazy(() => import("./EmailPhoneChange/Authorisation"))
		},
		[`${path}email-phone-change/review-requests/view`]: {
			title: "Review Email/Phone Change",
			icon: EmailPhoneChangeIcon,
			component: lazy(() => import("./EmailPhoneChange/Review/view"))
		},
		[`${path}email-phone-change/review-requests`]: {
			title: "Email/Phone Number Change Review Requests",
			icon: EmailPhoneChangeIcon,
			component: lazy(() => import("./EmailPhoneChange/Review"))
		},
		[`${path}password`]: {
			title: "Change Password",
			icon: ChangePasswordIcon,
			component: lazy(() => import("./ChangePassword"))
		},
		[`${path}individual-details`]: {
			title: "Details",
			component: lazy(() => import("./UserDetails"))
		},
		[`${path}PAYE/records`]: {
			title: "PAYE Records",
			component: lazy(() => import("./PAYE/Paye-Record"))
		},
		[`${path}PAYE/PayeSchedule`]: {
			title: "PAYE Schedule",
			component: lazy(() => import("./PAYE/Create-PAYE-assessment"))
		},
		[`${path}Paye-Review`]: {
			title: "Paye Details",
			component: lazy(() => import("./PAYE/Paye-Record/recordsView"))
		},
		[`${path}PAYE/authorizer-review`]: {
			title: "AUTHORIZATION",
			component: lazy(() => import("./PAYE/Authorisation/authView"))
		},
		[`${path}PAYE/authorize-PAYE-assessment`]: {
			title: "PAYE Authorization",
			component: lazy(() => import("./PAYE/Authorisation"))
		},
		[`${path}PAYE-Reviewer`]: {
			title: " Review PAYE",
			component: lazy(() => import("./PAYE/Reviewer"))
		},
		[`${path}Paye-Reviewer-view`]: {
			title: "Paye Reviewer view",
			component: lazy(() => import("./PAYE/Reviewer/reviewerView"))
		},
		[`${path}demand-notice/consoldemandnotice`]: {
			title: "Consolidated Demand Notice",
			component: lazy(
				() => import("./DemandNotice/Consolidation/consolidated")
			)
		},
		[`${path}demand-notice/generate-consoldemandnotice`]: {
			title: "Generate Consolidated Demand Notice",
			component: lazy(
				() =>
					import("./DemandNotice/Consolidation/generate-consolidated")
			)
		},
		[`${path}demand-notice/ConsolidatedView`]: {
			title: "Consolidated Read Only",
			component: lazy(
				() => import("./DemandNotice/Consolidation/consolidatedView")
			)
		},
		[`${path}PAYE-Assessment`]: {
			title: "PAYE-Assessment",
			component: lazy(() => import("./PAYE/PAYE-Assessment"))
		},
		[`${path}Assessment`]: {
			title: "PAYE-Assessment Continuation",
			component: lazy(() => import("./PAYE/PAYE-Assessment/assessment"))
		},
		[`${path}news-reel/publish`]: {
			title: `Publish`,
			icon: NewsReelIcon,
			component: lazy(() => import("./NewsReel/Generation/generate"))
		},
		[`${path}news-reel`]: {
			title: `News Reel`,
			icon: NewsReelIcon,
			component: lazy(() => import("./NewsReel/Generation"))
		},

		[`${path}withholding/assessment`]: {
			title: "Withholding Assessment",
			icon: WHTblackLogo,
			component: lazy(() => import("./WHT/Generation/generate"))
		},
		[`${path}withholding/records/view`]: {
			title: "Withholding Tax View Page",
			icon: WHTblackLogo,
			component: lazy(() => import("./WHT/Generation/view"))
		},
		[`${path}withholding/records`]: {
			title: "Withholding Tax Records",
			icon: WHTblackLogo,
			component: lazy(() => import("./WHT/Generation"))
		},
		[`${path}withholding/edit`]: {
			title: "Edit Withholding",
			icon: WHTblackLogo,
			component: lazy(
				() => import("./WHT/Generation/adminEditEmployerInformation")
			)
		},
		[`${path}withholding/authorisation/view`]: {
			title: "Authorisation View Page",
			icon: WHTblackLogo,
			component: lazy(() => import("./WHT/Authorisation/view"))
		},
		[`${path}withholding/authorisation`]: {
			title: "Authorisation Page",
			icon: WHTblackLogo,
			component: lazy(() => import("./WHT/Authorisation"))
		},
		[`${path}withholding/reviewer/view`]: {
			title: "Reviewer View",
			icon: WHTblackLogo,
			component: lazy(() => import("./WHT/Reviewer/view"))
		},
		[`${path}withholding/reviewer`]: {
			title: "Withholding Reviewer",
			icon: WHTblackLogo,
			component: lazy(() => import("./WHT/Reviewer"))
		},
		[`${path}capital-gain-tax/assessment`]: {
			title: "CGT Assessment",
			icon: CGTblackIcon,
			component: lazy(() => import("./CGT/Generation/generate"))
		},
		[`${path}capital-gain-tax/records/view`]: {
			title: "Capital Gain Tax View Page",
			icon: WHTblackLogo,
			component: lazy(() => import("./CGT/Generation/view"))
		},
		[`${path}capital-gain-tax/records`]: {
			title: "Capital Gain Tax Records",
			icon: CGTblackIcon,
			component: lazy(() => import("./CGT/Generation"))
		},
		[`${path}capital-gain-tax/reviewer/view`]: {
			title: "Capital Gain Tax View Page",
			icon: CGTblackIcon,
			component: lazy(() => import("./CGT/Reviewer/view"))
		},
		[`${path}capital-gain-tax/reviewer`]: {
			title: "Capital Gain Tax Review",
			icon: CGTblackIcon,
			component: lazy(() => import("./CGT/Reviewer"))
		},
		[`${path}capital-gain-tax/authorisation/view`]: {
			title: "Capital Gain Tax Review",
			icon: CGTblackIcon,
			component: lazy(() => import("./CGT/Authorizer/view"))
		},
		[`${path}capital-gain-tax/authorisation`]: {
			title: "Capital Gain Tax Authorisation",
			icon: CGTblackIcon,
			component: lazy(() => import("./CGT/Authorizer/index"))
		},
		[`${path}capital-gain-tax/edit`]: {
			title: "Capital Gain Tax Edit",
			icon: CGTblackIcon,
			component: lazy(() => import("./CGT/Generation/edit"))
		}
	};

	const routeBucket = isUser ? routes : adminRoutes;

	const route = routeBucket[pathname];

	const firstAllowedRoute = Object.keys(allowedRoutes || {}).find((key) =>
		Boolean(routeBucket[key])
	);

	const comp = routeBucket[firstAllowedRoute];

	useEffect(() => {
		function handleResize() {
			toggleSidebar(window.innerWidth > 768);
		}

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	function handleSidebarToggle() {
		if (window.innerWidth <= 768) {
			toggleSidebar(!sidebarOpen);
		}
	}

	return (
		<section className={styles.wrapper}>
			{sidebarOpen &&
				(isUser ? (
					<UserSidenav
						navRef={navRef}
						menuRef={menuRef}
						toggleSidebar={handleSidebarToggle}
					/>
				) : (
					<Sidenav
						navRef={navRef}
						menuRef={menuRef}
						toggleSidebar={handleSidebarToggle}
					/>
				))}
			<div className={styles.faux__sidenav} />
			<div className={styles.main__right}>
				<Header
					title={route?.title}
					icon={route?.icon && route?.icon}
					toggleSidebar={handleSidebarToggle}
				/>
				<Switch>
					<Route path={path} exact>
						<ErrorBoundary>
							<Suspense fallback={<Spinner />}>
								{isUser ? (
									<Home />
								) : !hasDashboard &&
								  !isGovernor &&
								  comp?.component ? (
									<comp.component />
								) : isGovernor ||
								  userInfo?.account_type === "admin" ||
								  userInfo?.account_type ===
										"Dashboard-Only" ? (
									<SpecialDashboard />
								) : (
									<Summary />
								)}
							</Suspense>
						</ErrorBoundary>
					</Route>
					{Object.keys(routeBucket).map((r) => {
						if (Object.keys(allowedRoutes).includes(r)) {
							const Component = routeBucket[r]?.component;
							return (
								Component && (
									<Route key={r} path={r}>
										<ErrorBoundary>
											<Suspense fallback={<Spinner />}>
												<Component />
											</Suspense>
										</ErrorBoundary>
									</Route>
								)
							);
						} else
							return (
								<Route key={"/restricted"} path={"/restricted"}>
									<h2>
										You are not authorised to visit that
										route{" "}
									</h2>
								</Route>
							);
					})}
					<Route path="*">
						<Redirect to="/404" />
					</Route>
				</Switch>
			</div>
		</section>
	);
};

export default Dashboard;
