import React, { useState } from "react";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";

import ArrowDown from "../../assets/arrowDown.svg";
import ArrowUp from "../../assets/arrowUp.svg";
import Logo from "../../assets/Header/enuguIRS50.svg";
import Avatar from "../../assets/Sidenav/avatar.svg";
import styles from "./style.module.css";

let userInfo = JSON.parse(localStorage.getItem("user_info"));

const UserSidenav = ({ navRef, menuRef, toggleSidebar }) => {
	let { url } = useRouteMatch();
	const history = useHistory();

	const [hasCGT, setHasCGT] = useState(false);
	const [hasPAYE, setHasPAYE] = useState(false);
	const [hasWHT, setHasWHT] = useState(false);
	const [hasPAYEFiling, setHasPAYEFiling] = useState(false);

	const allowedRoutes = (userInfo?.roles?.[0]?.Claims || []).reduce(
		(acc, cl) => {
			acc[cl.Route] = true;
			return acc;
		},
		{}
	);

	const routes = {
		[`${url}`]: {
			title: "Home",
			params: {
				exact: true
			}
		},
		[`${url}capital-gain-tax/self-service/records`]: {
			title: "Capital Gain Tax",
			shouldShow: hasCGT,
			setShouldShow: setHasCGT
		},
		[`${url}PAYE-records`]: {
			title: "PAYE Assessment",
			shouldShow: hasPAYE,
			setShouldShow: setHasPAYE
		},
		[`${url}withholding/records`]: {
			title: "WHT Assessment",
			shouldShow: hasWHT,
			setShouldShow: setHasWHT
		},
		[`${url}PAYE-filing-corporate`]: {
			title: "PAYE Filing",
			shouldShow: hasPAYEFiling,
			setShouldShow: setHasPAYEFiling
		}
	};

	return (
		<>
			<nav className={styles.nav} ref={navRef}>
				<div className={styles.logo}>
					<img src={Logo} alt="logo" />
				</div>
				<div className={styles.mobile__top}>
					<div>
						<img src={Avatar} alt="avatar" />
						<p>
							{userInfo.fullname}
							<br /> <span>{userInfo.email}</span>
						</p>
					</div>
					<button
						aria-labelledby="close sidebar"
						onClick={toggleSidebar}
					>
						X
					</button>
				</div>
				<ul className={styles.nav__list}>
					<li>
						<div>
							<NavLink
								exact
								to="/"
								activeClassName={styles.active}
								className={styles.nav__button}
								onClick={toggleSidebar}
							>
								<span>Home</span>
							</NavLink>
						</div>
						<hr className={styles.divider} />
					</li>
					{Object.keys(routes).map((route, i) => {
						const routeInfo = routes[route];
						if (!routeInfo.children) {
							if (!allowedRoutes[route]) return undefined;

							return (
								<li key={i}>
									<div>
										<NavLink
											exact
											to={route}
											activeClassName={styles.active}
											className={styles.nav__button}
											onClick={toggleSidebar}
										>
											<span>
												{routeInfo.icon && (
													<img
														src={routeInfo.icon}
														alt={`${routeInfo.title}`}
													/>
												)}
												{routeInfo.title}
											</span>
										</NavLink>
									</div>
									<hr className={styles.divider} />
								</li>
							);
						} else {
							return (
								<li
									className={
										!routeInfo.shouldShow
											? styles.no__display
											: ""
									}
									key={i}
								>
									<div>
										<button
											id={`${route}DropdownButton`}
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
											aria-label={routeInfo.title}
											className={routeInfo.className}
											onClick={routeInfo.onClick}
										>
											<span>
												<img
													src={routeInfo.icon}
													alt={`${routeInfo.title}`}
												/>
												{routeInfo.title}
											</span>
											<img
												src={
													routeInfo.state
														? ArrowUp
														: ArrowDown
												}
												alt="arrow icon"
											/>
										</button>
									</div>

									<div>
										<ul
											id={`${route}Dropdown`}
											aria-labelledby={`${route}DropdownButton`}
											className={styles.nav__list}
											style={{ margin: 0 }}
										>
											{Object.keys(
												routeInfo.children
											).map((child, index) => {
												const childInfo =
													routeInfo.children[child];
												if (!allowedRoutes[child])
													return undefined;

												!routeInfo.shouldShow &&
													routeInfo.setShouldShow(
														true
													);

												return (
													<>
														{routeInfo.state && (
															<li key={index}>
																<div
																	style={{
																		padding: 0
																	}}
																>
																	<NavLink
																		activeClassName={
																			styles.active
																		}
																		to={
																			child
																		}
																		className={
																			styles.nested__nav__list
																		}
																		onClick={
																			toggleSidebar
																		}
																	>
																		{
																			childInfo.title
																		}
																	</NavLink>
																</div>
															</li>
														)}
													</>
												);
											})}
										</ul>
									</div>
									<hr className={styles.divider} />
								</li>
							);
						}
					})}
					<li>
						<div>
							<NavLink
								exact
								to="/details"
								isActive={(_, loc) =>
									/details/i.test(loc.pathname)
								}
								activeClassName={styles.active}
								className={styles.nav__button}
								onClick={toggleSidebar}
							>
								<span>User Details</span>
							</NavLink>
						</div>
						<hr className={styles.divider} />
					</li>
					<li>
						<div>
							<NavLink
								exact
								to="/support"
								activeClassName={styles.active}
								className={styles.nav__button}
								onClick={toggleSidebar}
							>
								<span>Contact Support</span>
							</NavLink>
						</div>
						<hr className={styles.divider} />
					</li>
					<li>
						<div>
							<NavLink
								exact
								to="/password"
								activeClassName={styles.active}
								className={styles.nav__button}
								onClick={toggleSidebar}
							>
								<span>Change Password</span>
							</NavLink>
						</div>
						<hr className={styles.divider} />
					</li>
					<li>
						<div>
							<button
								className={styles.nav__button}
								onClick={() => {
									localStorage.removeItem("user_info");
									localStorage.removeItem("access_token");
									history.push("/login");
								}}
							>
								<span>Logout</span>
							</button>
						</div>
					</li>
				</ul>
			</nav>
			<div className={styles.background} onClick={toggleSidebar} />
		</>
	);
};

export default UserSidenav;
