import React, { useState } from "react";
import {
	NavLink,
	useHistory,
	useLocation,
	useRouteMatch
} from "react-router-dom";

import ArrowDown from "../../assets/arrowDown.svg";
import ArrowUp from "../../assets/arrowUp.svg";
import CGTicon from "../../assets/CGTicon.svg";
import annualReturnsIcon from "../../assets/Header/annualReturns.svg";
import Logo from "../../assets/Header/enuguIRS50.svg";
import hniIcon from "../../assets/Header/hniIcon.svg";
import makerChecker from "../../assets/Header/makerChecker.svg";
import NewsReelIcon from "../../assets/NewsReel/newspaper.svg";
import analyticsIcon from "../../assets/Sidenav/analytics.svg";
import anssidIcon from "../../assets/Sidenav/anssid.svg";
import Avatar from "../../assets/Sidenav/avatar.svg";
import billsIcon from "../../assets/Sidenav/bills.svg";
import changePasswordIcon from "../../assets/Sidenav/changePassword.svg";
import couponIcon from "../../assets/Sidenav/coupon.svg";
import dashboardIcon from "../../assets/Sidenav/dashboard.svg";
import emailPhoneChangeIcon from "../../assets/Sidenav/emailPhoneChange.svg";
import logoutIcon from "../../assets/Sidenav/logout.svg";
import mdasIcon from "../../assets/Sidenav/mdas.svg";
import receiptsIcon from "../../assets/Sidenav/receipts.svg";
import settingsIcon from "../../assets/Sidenav/settings.svg";
import totalCollectionIcon from "../../assets/Sidenav/totalCollections.svg";
import userManagementIcon from "../../assets/Sidenav/userManagement.svg";
import WHTicon from "../../assets/WHTicon.svg";
import styles from "./style.module.css";

// const coy_ANSCIMS_LOGO = process.env.REACT_APP_ANSCIMS_SIDEBBAR_LOGO;
const coy_ANSSID = process.env.REACT_APP_ANSSID;

let userInfo;
try {
	userInfo = JSON.parse(localStorage.getItem("user_info"));
} catch (e) {
	userInfo = {};
}

const mdaName = userInfo?.mdaModuleCoyName ?? "";

const Sidenav = ({ navRef, menuRef, toggleSidebar }) => {
	let { url } = useRouteMatch();
	const history = useHistory();

	const { pathname: currentLocation } = useLocation();
	const [settingsSubMenuOpen, toggleSettingsSubMenu] = useState(
		currentLocation.split("/")[1] === "settings" || false
	);
	const [hasSettings, setHasSettings] = useState(false);
	const [emailPhoneChangeSubMenuOpen, toggleEmailPhoneChangeSubMenuOpen] =
		useState(
			currentLocation.split("/")[1] === "email-phone-change" || false
		);
	const [hasEmailPhoneChange, setHasEmailPhoneChange] = useState(false);
	const [userMgntSubMenuOpen, toggleUserMgntSubMenu] = useState(
		currentLocation.split("/")[1] === "user-management" || false
	);
	const [hasUserMgnt, setHasUserMgnt] = useState(false);
	//NewRoute added
	const [MulticompanySubMenuOpen, toggleMulticompanySubMenu] = useState(
		currentLocation.split("/")[1] === "multi-company" || false
	);
	const [hasMulticompany, setHasMulticompany] = useState(false);

	const [billsSubMenuOpen, toggleBillsSubMenu] = useState(
		currentLocation.split("/")[1] === "bills" || false
	);
	const [hasBills, setHasBills] = useState(false);
	const [analyticsSubMenuOpen, toggleAnalyticsSubMenu] = useState(
		currentLocation.split("/")[1] === "analytics" || false
	);
	const [makerchecker, setMakerChecker] = useState(false);
	const [makercheckerSubMenuOpen, toggleMakercheckerSubMenu] = useState(
		currentLocation.split("/")[1] === "Maker-checker" || false
	);
	const [annualReturns, setAnnualReturns] = useState(false);
	const [annualReturnsSubMenuOpen, toggleAnnualReturnsSubMenu] = useState(
		currentLocation.split("/")[1] === "annual-returns" || false
	);
	const [hni, setHNI] = useState(false);
	const [hniSubMenuOpen, toggleHNISubMenu] = useState(
		currentLocation.split("/")[1] === "hni" || false
	);
	const [hasAnalytics, setHasAnalytics] = useState(false);
	const [anssidSubMenuOpen, toggleAnssidSubMenu] = useState(
		currentLocation.split("/")[1] === "statetin" || false
	);
	const [hasAnssid, setHasAnssid] = useState(false);
	const [receiptSubMenuOpen, toggleReceiptSubMenu] = useState(
		currentLocation.split("/")[1] === "receiptprt" || false
	);
	const [directAssessmentSubMenuOpen, toggleDirectAssessmentSubMenu] =
		useState(
			currentLocation.split("/")[1] === "direct-assessment" || false
		);
	const [hasDirectAssessment, setHasDirectAssessment] = useState(false);
	const [PAYESubMenuOpen, togglePAYESubMenu] = useState(
		currentLocation.split("/")[1] === "PAYE" || false
	);
	const [hasPAYE, setHasPAYE] = useState(false);

	const [hasReceipt, setHasReceipt] = useState(false);

	const [certificateSubMenuOpen, toggleCertificateSubMenu] = useState(
		currentLocation.split("/")[1] === "certificate" || false
	);
	const [hasCertificate, setHasCertificate] = useState(false);

	const [reducingBalance, setReducingBal] = useState(false);
	const [reducingBalanceSubMenuOpen, toggleReducingBalSubMenu] = useState(
		currentLocation.split("/")[1] === "reducing-balance" || false
	);

	// Demand Notice
	const [demandNotSubMenuOpen, toggledemandNotSubMenu] = useState(
		currentLocation.split("/")[1] === "demand-notice" || false
	);
	const [hasdemandNot, setHasdemandNot] = useState(false);

	// Withholding
	const [WithholdingNotSubMenuOpen, toggleWithholdingNotSubMenu] = useState(
		currentLocation.split("/")[1] === "Withholding" || false
	);
	const [hasWithholdingNot, setHasWithholdingNot] = useState(false);
	// capitalGainTax
	const [capitalGainTaxNotSubMenuOpen, toggleCapitalGainTaxNotSubMenu] =
		useState(currentLocation.split("/")[1] === "capital-gain-tax" || false);
	const [hascapitalGainTaxNot, setHascapitalGainTaxNot] = useState(false);
	//Coupon
	const [couponSubMenuOpen, toggleCouponSubMenu] = useState(
		currentLocation.split("/")[1] === "coupon" || false
	);
	const [hasCoupon, setHasCoupon] = useState(false);

	//ENNSAA
	const [ENNSAASubMenuOpen, toggleENNSAASubMenu] = useState(
		currentLocation.split("/")[1] === "ENNSAA" || false
	);
	const [hasENNSAA, setHasENNSAA] = useState(false);
	//News Reel
	const [hasNewsReel, setHasNewsReel] = useState(false);

	//Withholding
	// const [WithholdingNotSubMenuOpen, toggleWithholdingNotSubMenu] = useState(
	// 	currentLocation.split("/")[1] === "Withholding" || false
	// );
	// const [hasWithholdingNot, setHasWithholdingNot] = useState(false);

	//Purchasing-Tax
	const [purchaseTax, setPurchaseTax] = useState(false);
	const [purchasingTaxSubMenuOpen, togglePurchasingTaxSubMenu] = useState(
		currentLocation.split("/")[1] === "purchasing-tax" || false
	);

	const allowedRoutes = (userInfo?.roles?.[0]?.Claims || []).reduce(
		(acc, cl) => {
			acc[cl.Route] = true;
			return acc;
		},
		{}
	);

	const routes = {
		[`${url}`]: {
			title: "Dashboard",
			params: {
				exact: true
			},
			icon: dashboardIcon
		},
		[`${url}total-collections`]: {
			title: "Total Collections",
			icon: totalCollectionIcon
		},
		[`${url}bills`]: {
			title: "Bills",
			icon: billsIcon,
			state: billsSubMenuOpen,
			shouldShow: hasBills,
			setShouldShow: setHasBills,
			onClick: () => {
				toggleBillsSubMenu(!billsSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "bills"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}bills/cleared-bills`]: {
					title: "Cleared Bills"
				},
				[`${url}bills/uncleared-bills`]: {
					title: "Uncleared Bills"
				}
			}
		},
		//new route for Multi-company
		[`${url}multi-company`]: {
			title: "MDA Multi-Company",
			icon: mdasIcon,
			state: MulticompanySubMenuOpen,
			shouldShow: hasMulticompany,
			setShouldShow: setHasMulticompany,
			onClick: () => {
				toggleMulticompanySubMenu(!MulticompanySubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "multi-company"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}multi-company/mdaprofile`]: {
					title: "Profile Properties"
				},
				[`${url}multi-company/billing`]: {
					title: "Prepare Bills"
				},
				[`${url}multi-company/authorise`]: {
					title: "Authorise Bills"
				},
				// [`${url}multi-company/metered-bill`]: {
				// 	title: "Prepare Bills (Metered Customers)"
				// },
				[`${url}multi-company/create-water-bill`]: {
					title: "Bill Preparation"
				},
				[`${url}multi-company/orba-market`]: {
					title: "Orba Market"
				},
				[`${url}multi-company/orba-market-authorise`]: {
					title: "Authorise Orba Market"
				},
				[`${url}multi-company/new-haven`]: {
					title: "New Haven Shops"
				},
				[`${url}multi-company/new-haven-authorise`]: {
					title: "Authorise New Haven"
				},
				[`${url}multi-company/water`]: {
					title: "Water"
				},
				[`${url}multi-company/settings`]: {
					title: "Settings"
				}
			}
		},

		[`${url}hni`]: {
			title: "HNI",
			icon: hniIcon,
			state: hniSubMenuOpen,
			shouldShow: hni,
			setShouldShow: setHNI,
			onClick: () => {
				toggleHNISubMenu(!hniSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "hni"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}hni/tax`]: {
					title: "HNI Tax"
				},
				[`${url}hni/setup`]: {
					title: "HNI Setup"
				},
				[`${url}hni/authorise`]: {
					title: "Authorise HNI"
				}
			}
		},
		[`${url}mdas`]: {
			title: "MDAs",
			icon: mdasIcon
		},
		[`${url}statetin`]: {
			title: coy_ANSSID,
			icon: anssidIcon,
			state: anssidSubMenuOpen,
			shouldShow: hasAnssid,
			setShouldShow: setHasAnssid,
			onClick: () => {
				toggleAnssidSubMenu(!anssidSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "statetin"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}statetin/authorize`]: {
					title: "Authorize"
				},
				[`${url}statetin/individual`]: {
					title: `${coy_ANSSID} Individual`
				},
				[`${url}statetin/corporate`]: {
					title: `${coy_ANSSID} Organization`
				},
				[`${url}statetin/audit`]: {
					title: `${coy_ANSSID} - Auditor`
				}
			}
		},
		[`${url}direct-assessment`]: {
			title: "Direct Assessment",
			icon: userManagementIcon,
			state: directAssessmentSubMenuOpen,
			shouldShow: hasDirectAssessment,
			setShouldShow: setHasDirectAssessment,
			onClick: () => {
				toggleDirectAssessmentSubMenu(!directAssessmentSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "direct-assessment"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}direct-assessment/records`]: {
					title: "Assessment"
				},
				[`${url}direct-assessment/authorize-direct-assessment`]: {
					title: "Authorize"
				},
				[`${url}direct-assessment/match`]: {
					title: "Match"
				}
				// [`${url}direct-assessment/generate-notice`]: {
				// 	title: "Generate Notice"
				// },
				// [`${url}direct-assessment/workflow-setup`]: {
				// 	title: "Workflow Setup"
				// },
				// [`${url}direct-assessment/settings`]: {
				// 	title: "Settings"
				// }
			}
		},
		[`${url}Maker-checker`]: {
			title: "Maker Checker",
			icon: makerChecker,
			state: makercheckerSubMenuOpen,
			shouldShow: makerchecker,
			setShouldShow: setMakerChecker,
			onClick: () => {
				toggleMakercheckerSubMenu(!makercheckerSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "Maker-checker"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}Maker-checker/authoriser`]: {
					title: "Authoriser"
				},
				[`${url}Maker-checker/inputter`]: {
					title: "Inputter"
				},
				[`${url}Maker-checker/genreceipt`]: {
					title: "Generate Reciept"
				}
			}
		},
		//reducing balance
		[`${url}reducing-balance/reducing-balance-receipts`]: {
			title: "Reducing Balance",
			icon: receiptsIcon,
			state: reducingBalanceSubMenuOpen,
			shouldShow: reducingBalance,
			setShouldShow: setReducingBal,
			onClick: () => {
				toggleReducingBalSubMenu(!reducingBalanceSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "reducing-balance"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button
		},
		[`${url}annual-returns`]: {
			title: "E-Filing",
			icon: annualReturnsIcon,
			state: annualReturnsSubMenuOpen,
			shouldShow: annualReturns,
			setShouldShow: setAnnualReturns,
			onClick: () => {
				toggleAnnualReturnsSubMenu(!annualReturnsSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "paye-filing"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}paye-filing/admin-filing-table`]: {
					title: "PAYE Filing"
				},
				[`${url}paye-filing/review`]: {
					title: "Review PAYE Filing"
				},
				[`${url}paye-filing/search`]: {
					title: "Authorise PAYE Filing"
				}
				// [`${url}wht-filing/admin-filing-table`]: {
				// 	title: "WHT Filing"
				// },
				// [`${url}wht-filing/review`]: {
				// 	title: "Review WHT Filing"
				// },
				// [`${url}wht-filing/search`]: {
				// 	title: "Authorise WHT Filing"
				// }
				// [`${url}paye-filing/employee`]: {
				// 	title: "E-Filing Employee"
				// },
				// [`${url}paye-filing/wht-compliance`]: {
				// 	title: "WHT Compliance"
				// },
				// [`${url}paye-filing/paye-compliance`]: {
				// 	title: "PAYE Compliance"
				// }
			}
		},
		//PAYE //
		[`${url}PAYE`]: {
			title: "PAYE",
			icon: userManagementIcon,
			state: PAYESubMenuOpen,
			shouldShow: hasPAYE,
			setShouldShow: setHasPAYE,
			onClick: () => {
				togglePAYESubMenu(!PAYESubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "PAYE"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				// [`${url}PAYE/create-PAYE-assessment`]: {
				// 	title: "Create PAYE Assessment"
				// },
				[`${url}PAYE/authorize-PAYE-assessment`]: {
					title: "Authorize PAYE Assessment"
				},
				[`${url}PAYE/generate-PAYE-remittance`]: {
					title: "Generate PAYE Remittance"
				},
				[`${url}PAYE/workflow-setup`]: {
					title: "Workflow Setup"
				},
				[`${url}PAYE/settings`]: {
					title: "Settings"
				},
				[`${url}PAYE/records`]: {
					title: "PAYE Records"
				},
				[`${url}PAYE/authorize-PAYE-assessment`]: {
					title: "Authorisation"
				},
				[`${url}PAYE-Assessment`]: {
					title: "PAYE-Assessment"
				},
				[`${url}PAYE-Reviewer`]: {
					title: "Review PAYE"
				}
			}
		},

		//Demand Notice//
		[`${url}demand-notice`]: {
			title: "Demand Notice",
			icon: userManagementIcon,
			state: demandNotSubMenuOpen,
			shouldShow: hasdemandNot,
			setShouldShow: setHasdemandNot,
			onClick: () => {
				toggledemandNotSubMenu(!demandNotSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "demand-notice"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}demand-notice/consoldemandnotice`]: {
					title: "Consolidated "
				}
			}
		},
		//ENNSAA
		[`${url}ENNSAA`]: {
			title: "ENSSAA",
			icon: annualReturnsIcon,
			state: ENNSAASubMenuOpen,
			shouldShow: hasENNSAA,
			setShouldShow: setHasENNSAA,
			onClick: () => {
				toggleENNSAASubMenu(!ENNSAASubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "ENNSAA"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}ENNSAA/records`]: {
					title: "ENSSAA Records"
				},
				[`${url}ENNSAA/authorizer`]: {
					title: "ENSSAA Authoriser"
				},
				[`${url}ENNSAA/preparebill`]: {
					title: "Prepare Bill"
				}
				// [`${url}ENNSAA/setup`]: {
				// 	title: "ENSSAA Setup"
				// }
			}
		},
		// [`${url}Withholding`]: {
		// 	title: "Withholding",
		// 	icon: userManagementIcon,
		// 	state: WithholdingNotSubMenuOpen,
		// 	shouldShow: hasWithholdingNot,
		// 	setShouldShow: setHasWithholdingNot,
		// 	onClick: () => {
		// 		toggleWithholdingNotSubMenu(!WithholdingNotSubMenuOpen);
		// 	},
		// 	className:
		// 		currentLocation.split("/")[1] === "Withholding"
		// 			? [styles.nav__button, styles.active__submenu].join(" ")
		// 			: styles.nav__button,
		// 	children: {
		// 		[`${url}Withholding`]: {
		// 			title: "Withholding "
		// 		}
		// 	}
		// },

		[`${url}pt`]: {
			title: "Purchasing-Tax (PT)",
			icon: userManagementIcon,
			state: purchasingTaxSubMenuOpen,
			shouldShow: purchaseTax,
			setShouldShow: setPurchaseTax,
			onClick: () => {
				togglePurchasingTaxSubMenu(!purchasingTaxSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "pt"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,

			children: {
				[`${url}pt/pt-assessment`]: {
					title: "PT Assessment"
				},
				[`${url}pt/pt-authorizer`]: {
					title: "PT Authoriser"
				},
				[`${url}pt/pt-reviewer`]: {
					title: "PT Reviewer"
				},
				[`${url}pt/pt-search`]: {
					title: "PT Records"
				}
				// [`${url}pt/pt-setup`]: {
				// 	title: "PT setup"
				// }
			}
		},

		//Withholding//
		[`${url}withholding`]: {
			title: "Withholding",
			icon: WHTicon,
			state: WithholdingNotSubMenuOpen,
			shouldShow: hasWithholdingNot,
			setShouldShow: setHasWithholdingNot,
			onClick: () => {
				toggleWithholdingNotSubMenu(!WithholdingNotSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "Withholding"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}withholding/assessment`]: {
					title: "WHT Assessment"
				},
				[`${url}withholding/records`]: {
					title: "WHT Records"
				},
				[`${url}withholding/reviewer`]: {
					title: "WHT Reviewer"
				},
				[`${url}withholding/authorisation`]: {
					title: "WHT Authorisation"
				}
			}
		},
		//Capital Gain Tax//
		[`${url}capital-gain-tax`]: {
			title: "Capital Gain Tax",
			icon: CGTicon,
			state: capitalGainTaxNotSubMenuOpen,
			shouldShow: hascapitalGainTaxNot,
			setShouldShow: setHascapitalGainTaxNot,
			onClick: () => {
				toggleCapitalGainTaxNotSubMenu(!capitalGainTaxNotSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "capital-gain-tax"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}capital-gain-tax/assessment`]: {
					title: "CGT Assessment"
				},
				[`${url}capital-gain-tax/records`]: {
					title: "CGT Records"
				},
				[`${url}capital-gain-tax/reviewer`]: {
					title: "CGT Reviewer"
				},
				[`${url}capital-gain-tax/authorisation`]: {
					title: "CGT Authorisation"
				}
			}
		},
		[`${url}certificate`]: {
			title: "Certificate",
			icon: receiptsIcon,
			state: certificateSubMenuOpen,
			shouldShow: hasCertificate,
			setShouldShow: setHasCertificate,
			onClick: () => {
				toggleCertificateSubMenu(!certificateSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "certificate"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}certificate/da`]: {
					title: "DA Automated Certificate"
				},
				[`${url}certificate/da-manual-entry`]: {
					title: "DA Manual Entry"
				},
				[`${url}certificate/da-manual-entry-list`]: {
					title: "DA Manual Entry Cert. List"
				},
				[`${url}certificate/authorise/da`]: {
					title: "Authorise Certificates"
				}
			}
		},
		[`${url}coupon`]: {
			title: "Coupon",
			icon: couponIcon,
			state: couponSubMenuOpen,
			shouldShow: hasCoupon,
			setShouldShow: setHasCoupon,
			onClick: () => {
				toggleCouponSubMenu(!couponSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "coupon"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}coupon/generation`]: {
					title: "Coupon Generation"
				},
				[`${url}coupon/authorisation`]: {
					title: "Coupon Authorisation"
				},
				[`${url}coupon/review`]: {
					title: "Coupon Review"
				},
				[`${url}coupon/purchase/records`]: {
					title: "Bulk Purchase"
				},
				[`${url}coupon/validation`]: {
					title: "Coupon Validation"
				},
				[`${url}coupon/downloadable-coupons`]: {
					title: "Coupon Download"
				}
				// [`${url}coupon/serial-number-record`]: {
				// 	title: "Serial Number Record"
				// },
				// [`${url}coupon/sold`]: {
				// 	title: "Coupon Sold Record"
				// },
				// [`${url}coupon/all`]: {
				// 	title: "All Coupon Record"
				// }
			}
		},

		//news reel start
		[`${url}news-reel`]: {
			title: "News Reel",
			icon: NewsReelIcon,
			shouldShow: hasNewsReel,
			setShouldShow: setHasNewsReel
		},
		//news reel end

		[`${url}analytics`]: {
			title: "Analytics",
			icon: analyticsIcon,
			state: analyticsSubMenuOpen,
			shouldShow: hasAnalytics,
			setShouldShow: setHasAnalytics,
			onClick: () => {
				toggleAnalyticsSubMenu(!analyticsSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "analytics"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}analytics`]: {
					title: "JTB Report"
				},
				[`${url}lgaanalytics`]: {
					title: "LGA Analytics"
				},
				[`${url}mdaanalytics`]: {
					title: "MDA Analytics"
				},
				[`${url}revenueheadanalytics`]: {
					title: "Revenue Head"
				},
				[`${url}handlingsystemanalytics`]: {
					title: "Handling System"
				},
				[`${url}billgroupanalytics`]: {
					title: "Billing Group"
				},
				[`${url}mdaoptionsanalytics`]: {
					title: "Top & Bottom MDAs"
				}
			}
		},
		[`${url}receipt`]: {
			title: "Receipt",
			icon: receiptsIcon,
			state: receiptSubMenuOpen,
			shouldShow: hasReceipt,
			setShouldShow: setHasReceipt,
			onClick: () => {
				toggleReceiptSubMenu(!receiptSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "receiptprt"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}receiptprt/receiptref`]: {
					title: "By Payment Reference"
				},
				//				[`${url}receiptprt/receiptours`]: {
				//					title: "By Norlics Receipt No."
				//				},
				[`${url}receiptprt/receiptswitch`]: {
					title: "By Receipt No."
				},
				[`${url}receiptprt/receiptinv`]: {
					title: "By Invoice Number"
				},
				[`${url}receiptprt/mda.receipt`]: {
					title: "By mda receipt"
				},
				[`${url}receiptprt/receipts`]: {
					title: "Receipts"
				},
				[`${url}receiptprt/payment-history`]: {
					title: "Payment History"
				}
			}
		},
		[`${url}user-management`]: {
			title: "User Management",
			icon: userManagementIcon,
			state: userMgntSubMenuOpen,
			shouldShow: hasUserMgnt,
			setShouldShow: setHasUserMgnt,
			onClick: () => {
				toggleUserMgntSubMenu(!userMgntSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "user-management"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}user-management/manage-users`]: {
					title: "Manage Users"
				},
				[`${url}user-management/manage-claims`]: {
					title: "Manage Claims"
				},
				[`${url}user-management/manage-roles`]: {
					title: "Manage Roles"
				},
				[`${url}user-management/statetin-agent`]: {
					title: `View ${coy_ANSSID} Agent`
				},
				[`${url}user-management/deactivate-agent`]: {
					title: "Deactivated Agent"
				},
				[`${url}user-management/admin-change-password`]: {
					title: "Admin Change Password"
				}
			}
		},
		[`${url}email-phone-change`]: {
			title: "Email/Phone Change",
			icon: emailPhoneChangeIcon,
			state: emailPhoneChangeSubMenuOpen,
			shouldShow: hasEmailPhoneChange,
			setShouldShow: setHasEmailPhoneChange,
			onClick: () => {
				toggleEmailPhoneChangeSubMenuOpen(!emailPhoneChangeSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "email-phone-change"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}email-phone-change/auth-requests`]: {
					title: "Authorisation"
				},
				[`${url}email-phone-change/review-requests`]: {
					title: "Review"
				},
				[`${url}email-phone-change/record`]: {
					title: "Record"
				},
				[`${url}email-phone-change/change`]: {
					title: "Change"
				}
			}
		},
		[`${url}settings`]: {
			title: "Settings",
			icon: settingsIcon,
			state: settingsSubMenuOpen,
			shouldShow: hasSettings,
			setShouldShow: setHasSettings,
			onClick: () => {
				toggleSettingsSubMenu(!settingsSubMenuOpen);
			},
			className:
				currentLocation.split("/")[1] === "settings"
					? [styles.nav__button, styles.active__submenu].join(" ")
					: styles.nav__button,
			children: {
				[`${url}settings/id-details`]: {
					title: "ID Details"
				},
				[`${url}settings/statetin-setup`]: {
					title: `${coy_ANSSID} Setup`
				},
				[`${url}settings/monthly-salary-range`]: {
					title: "Monthly Salary Range"
				},
				[`${url}settings/revenue-details`]: {
					title: "Revenue Details"
				}
			}
		}
	};

	return (
		<>
			<nav className={styles.nav} ref={navRef}>
				<div className={styles.logo}>
					<img src={Logo} alt="logo" />
					<p className={styles.mda__name}>{mdaName}</p>
				</div>
				<div className={styles.mobile__top}>
					<div>
						<img src={Avatar} alt="avatar" />
						<p>
							{userInfo.fullname}
							<br /> <span>{userInfo.email}</span>
						</p>
					</div>
					<button
						aria-labelledby="close sidebar"
						onClick={toggleSidebar}
					>
						X
					</button>
				</div>
				<ul className={styles.nav__list}>
					{Object.keys(routes).map((route, i) => {
						const routeInfo = routes[route];
						if (!routeInfo.children) {
							if (!allowedRoutes[route]) return undefined;

							return (
								<li key={i}>
									<div>
										<NavLink
											exact
											to={route}
											activeClassName={styles.active}
											className={styles.nav__button}
											onClick={toggleSidebar}
										>
											<span>
												{routeInfo.icon && (
													<img
														src={routeInfo.icon}
														alt={`${routeInfo.title}`}
													/>
												)}
												{routeInfo.title}
											</span>
										</NavLink>
									</div>
								</li>
							);
						} else {
							return (
								<li
									className={
										!routeInfo.shouldShow
											? styles.no__display
											: ""
									}
									key={i}
								>
									{routeInfo.title === "User Management" && (
										<hr className={styles.divider} />
									)}
									<div>
										<button
											id={`${route}DropdownButton`}
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
											aria-label={routeInfo.title}
											className={routeInfo.className}
											onClick={routeInfo.onClick}
										>
											<span>
												<img
													src={routeInfo.icon}
													alt={`${routeInfo.title}`}
												/>
												{routeInfo.title}
											</span>
											<img
												src={
													routeInfo.state
														? ArrowUp
														: ArrowDown
												}
												alt="arrow icon"
											/>
										</button>
									</div>

									<div>
										<ul
											id={`${route}Dropdown`}
											aria-labelledby={`${route}DropdownButton`}
											className={styles.nav__list}
											style={{ margin: 0 }}
										>
											{Object.keys(
												routeInfo.children
											).map((child, index) => {
												const childInfo =
													routeInfo.children[child];
												if (!allowedRoutes[child])
													return undefined;

												!routeInfo.shouldShow &&
													routeInfo.setShouldShow(
														true
													);

												return (
													<>
														{routeInfo.state && (
															<li key={index}>
																<div
																	style={{
																		padding: 0
																	}}
																>
																	<NavLink
																		activeClassName={
																			styles.active
																		}
																		to={
																			child
																		}
																		className={
																			styles.nested__nav__list
																		}
																		onClick={
																			toggleSidebar
																		}
																	>
																		{
																			childInfo.title
																		}
																	</NavLink>
																</div>
															</li>
														)}
													</>
												);
											})}
										</ul>
									</div>
								</li>
							);
						}
					})}
					<li>
						<hr className={styles.divider} />
						<div>
							<NavLink
								exact
								to="/password"
								activeClassName={styles.active}
								className={styles.nav__button}
								onClick={toggleSidebar}
							>
								<span>
									<img
										src={changePasswordIcon}
										alt="change password"
									/>
									Change Password
								</span>
							</NavLink>
						</div>
					</li>
					<li>
						<div>
							<button
								className={styles.nav__button}
								onClick={() => {
									localStorage.removeItem("user_info");
									localStorage.removeItem("access_token");
									history.push("/login");
								}}
							>
								<span>
									<img src={logoutIcon} alt="logout" />
									Logout
								</span>
							</button>
						</div>
					</li>
				</ul>
			</nav>
			{/* <div className={styles.menu__toggle} ref={menuRef}>
				<button
					className={styles.menu__button}
					onClick={() => {
						navRef.current.classList.toggle(styles.no__display);
						menuRef.current.classList.toggle(
							styles.collapsed__menu
						);
					}}
				></button>
				<div
					onClick={() => {
						navRef.current.classList.add(styles.no__display);
						menuRef.current.classList.add(styles.collapsed__menu);
					}}
				></div>
			</div> */}
			<div className={styles.background} onClick={toggleSidebar} />
		</>
	);
};

export default Sidenav;
